<template>
    <div>
        <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('read')">
            <Filter @submit="getDataTable()" ref="filter"/>
        </Fieldset>

        <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="noo_id" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit" v-if="permission.includes('read')">
            <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <div>
                        <!-- <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" v-if="permission.includes('download')" />
                        <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" v-if="permission.includes('download')" /> -->
                        <h4>RESET OUTLET</h4>
                    </div>
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                    </span>
                </div>
            </template>
            <Column field="number" header="#" :style="{width:'20px'}" >
                <template #body="slotProps">
                {{ slotProps.data.number }}
                </template>
            </Column>
            <Column field="outlet_code" header="Outlet Code">
                <template #body="slotProps">
                    {{slotProps.data.outlet_code}}
                </template>
            </Column>
            <Column field="outlet_name" header="Outlet Name">
                <template #body="slotProps">
                    {{slotProps.data.outlet_name}}
                </template>
            </Column>
            <Column field="territory_name" header="Territory">
                <template #body="slotProps">
                    {{slotProps.data.territory_name}}
                </template>
            </Column>
            <Column field="city_name" header="Town">
                <template #body="slotProps">
                    {{slotProps.data.city_name}}
                </template>
            </Column>
            <Column field="lat" header="Latitude">
                <template #body="slotProps">
                    {{slotProps.data.lat}}
                </template>
            </Column>
            <Column field="lng" header="Longitude">
                <template #body="slotProps">
                    {{slotProps.data.lng}}
                </template>
            </Column>
            <Column field="outlet_phone" header="Contact Number">
                <template #body="slotProps">
                    {{slotProps.data.outlet_phone}}
                </template>
            </Column>
            <Column field="outlet_owner" header="Contact Person">
                <template #body="slotProps">
                    {{slotProps.data.outlet_owner}}
                </template>
            </Column>
            <Column field="outlet_address" header="Outlet Address">
                <template #body="slotProps">
                    {{slotProps.data.outlet_address}}
                </template>
            </Column>
            <Column header="Action" headerStyle="min-width:10rem;">
                <template #body="slotProps">
                    <div v-if="slotProps.data.is_tag == 'y'">
                        <Button icon="pi pi-refresh" class="p-button-rounded p-button-danger mr-2" @click="resetItem(slotProps.data)"  v-if="permission.includes('update')"/>
                    </div>
                </template>
            </Column>
            <template #empty>
                No data found.
            </template>
            <template #loading>
                Loading data. Please wait.
            </template>
            <template #footer>
                In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
            </template>
        </DataTable>

        <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]"  v-if="permission.includes('read')">
            <template>
                {{totalItemsCount}}
            </template>
        </Paginator>

        <Reset @submit="getDataTable()" :item="item" ref="reset"/>
    </div>
</template>

<script>

import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../../utils/helpers';
import Filter from '../../../components/master/reset-gps/outlet/Filter.vue';
import Reset from '../../../components/master/reset-gps/outlet/Reset.vue';

export default {
    components: {
		'Filter': Filter,
		'Reset': Reset,
	},
    data() {
        return {
            // loading
            loading: false,

            // edit,reset
            item: {},

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // RESET
		resetItem(item) {
			this.item = item;
            
            this.$refs.reset.resetItemDialog = true;
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/reset-gps',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "territory_id" : this.$refs.filter.filters.territory_id,
                    "city_id" : this.$refs.filter.filters.city_id,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
              this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
    }
}
</script>
