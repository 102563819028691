<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <!-- reset gps outlet -->
                <div>
                    <ResetOutlet ref="ResetOutlet"/>
                </div> 

                <!-- reset gps distributor -->
                <div>
                    <ResetDistributor ref="ResetDistributor"/>
                </div> 

            </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Error from '../../../components/Error.vue';
import ResetOutlet from './ResetOutlet.vue';
import ResetDistributor from './ResetDistributor.vue';

export default {
    components: {
		'Error': Error,
		'ResetOutlet': ResetOutlet,
		'ResetDistributor': ResetDistributor,
	},
    data() {
        return {
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    watch: {
    },
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
    }
}
</script>
